<template>
  <div class="leader">
<!--    导航-->
    <div class="dh">
      <div class="navigation">
        <div class="ico" @click="go()">
          <img class="icon" src="../assets/img15.png">
          <p class="designation">华钛信息</p>
        </div>
        <div class="selectivity">
          <div class="alternative">
            <div class="xzx1 xzxz1">
              <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
              <div class="nav1 nav">
                <div class="navxx">
                  <span>行业方案</span>
                  <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                  <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzData" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../assets/img188.png">
                </div>
              </div>
            </div>
            <div class="xzx2 xzxz1">
              <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
              <div class="nav2 nav">
                <div class="navxx">
                  <span>自主产品</span>
                  <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                  <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../assets/img189.png">
                </div>
              </div>
            </div>
            <div class="xzx3 xzxz1">
              <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
              <div class="nav3 nav">
                <div class="navxx">
                  <span>服务范围</span>
                  <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                  <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
                </div>
                <div class="lin-xx">
                  <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                    <span class="lin-cut"></span>
                    <p @click="din(item)">{{item.hzz}}</p>
                  </div>
                </div>
                <div class="pt">
                  <img src="../assets/img190.png">
                </div>
              </div>
            </div>
            <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
              <span class="xzxz" @click="din(item)">{{item.cname}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  顶层-->
    <div class="overstory">
      <div class="banner">
        <el-carousel height="640px">
          <el-carousel-item v-for="(item, index) in baData" :key="index">
            <div class="bat" :style="'background-image:url('+item.ban+');'">
              <div class="zsd">
                <div class="nong">
                  <span>{{item.bnr}}</span>
                  <span>{{item.bbt}}</span>
                  <span>{{item.bft}}</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--    二层-->
    <div class="bilaminar">
      <div class="zss">
        <!--    标题-->
        <div class="spreadhead">
          <div class="subhead">
            <div class="moit">
              <img class="img3" src="../assets/img3.png">
              <span>业务领域</span>
              <img class="img4" src="../assets/img4.png">
            </div>
          </div>
          <span class="details">提供数字运营服务、软件开发、云计算、软件外包等各类信息化产品和服务。</span>
        </div>
        <!--      Tab选项-->
        <div class="options">
          <ul class="select">
            <li @click="curss=0">
              <p :class="{ active: curss == 0 }">
              <span class="message">
                <span class="numbers" :class="{ active: curss == 0 }">01</span>
                <span class="sponsor">
                  <span :class="{ active: curss == 0 }">金融行业</span>
                  <span :class="{ active: curss == 0 }">金融大数据信息平台</span>
                </span>
                <span class="jt">
                  <span :class="{ active: curss == 0 }">
<!--                    <img src="../assets/img5.png">-->
                  </span>
                </span>
              </span>
              </p>
            </li>
            <li @click="curss=1">
              <p :class="{ active: curss == 1 }">
              <span class="message">
                <span class="numbers" :class="{ active: curss == 1 }">02</span>
                <span class="sponsor">
                  <span :class="{ active: curss == 1 }">制造行业</span>
                  <span :class="{ active: curss == 1 }">为企业数字化赋能</span>
                </span>
                <span class="jt">
                  <span :class="{ active: curss == 0 }">
<!--                    <img src="../assets/img5.png">-->
                  </span>
                </span>
              </span>
              </p>
            </li>
            <li @click="curss=2">
              <p :class="{ active: curss == 2 }">
              <span class="message">
                <span class="numbers" :class="{ active: curss == 2 }">03</span>
                 <span class="sponsor">
                  <span :class="{ active: curss == 2 }">零售行业</span>
                  <span :class="{ active: curss == 2 }">精准数字化营销</span>
                </span>
                <span class="jt">
                  <span :class="{ active: curss == 0 }">
<!--                    <img src="../assets/img5.png">-->
                  </span>
                </span>
              </span>
              </p>
            </li>
            <li @click="curss=3">
              <p :class="{ active: curss == 3 }">
              <span class="message">
                <span class="numbers" :class="{ active: curss == 3 }">04</span>
                 <span class="sponsor">
                  <span :class="{ active: curss == 3 }">汽车行业</span>
                  <span :class="{ active: curss == 3 }">车企智能网联化</span>
                </span>
                <span class="jt" :class="{ active: curss == 0 }">
                  <span :class="{ active: curss == 0 }">
<!--                    <img src="../assets/img5.png">-->
                  </span>
                </span>
              </span>
              </p>
            </li>
          </ul>
          <div class="cabient" v-show="curss==0">
            <div class="explain">
              <span class="duiying">金融行业</span>
              <img src="../assets/img6.png">
              <span class="shuoming">自成立起便专注于金融科技领域，并基于金融业的银行、证券、基金、保险等业务系统，提供端到端的软件服务。</span>
            </div>
            <div class="carousel">
              <el-carousel trigger="click" height="233px" :autoplay="true">
                <el-carousel-item v-for="(item, index) in Dataes" :key="index">
                  <div class="img-box">
                    <img :src="item.icon">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="cabient" v-show="curss==1">
            <div class="explain">
              <span class="duiying">制造行业</span>
              <img src="../assets/img6.png">
              <span class="shuoming">华钛信息数据科学平台为企业提供实时数据处理、实时业务、数据中台及AI建设能力，助力企业客户轻松构建智能化数据应用，完成数据智能化转型。</span>
            </div>
            <div class="carousel">
              <el-carousel trigger="click" height="233px" :autoplay="true">
                <el-carousel-item v-for="(item, index) in Dataes1" :key="index">
                  <div class="img-box">
                    <img :src="item.icon">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="cabient" v-show="curss==2">
            <div class="explain">
              <span class="duiying">零售行业</span>
              <img src="../assets/img6.png">
              <span class="shuoming">提供数据驱动运营所需的所有工具，从洞察到收益，构建全渠道客户增长体系，精准化营销方案，以数据辅助企业决策。</span>
            </div>
            <div class="carousel">
              <el-carousel trigger="click" height="233px" :autoplay="true">
                <el-carousel-item v-for="(item, index) in Dataes2" :key="index">
                  <div class="img-box">
                    <img :src="item.icon">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="cabient" v-show="curss==3">
            <div class="explain">
              <span class="duiying">汽车行业</span>
              <img src="../assets/img6.png">
              <span class="shuoming">在软件定义汽车的浪潮下，华钛信息为整车厂、车联网、汽车租赁公司等车企伙伴提供定制化的智能化、网联化解决方案，助力车企完成数字驱动转型。</span>
            </div>
            <div class="carousel">
              <el-carousel trigger="click" height="233px" :autoplay="true">
                <el-carousel-item v-for="(item, index) in Dataes3" :key="index">
                  <div class="img-box">
                    <img :src="item.icon">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    三层-->
    <div class="trilaminar">
      <!--      标题-->
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>最新产品</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
        <span class="details">华钛提供优秀的产品内容，助力您的业务腾飞。</span>
      </div>
      <!--      tab标签切换-->
      <div class="group1">
        <div class="group2">
          <ul>
            <li @click="cur=0">
              <p :class="{ active: cur == 0 }">供应商管理系统</p>
              <p :class="{ underline: cur == 0 }"></p>
            </li>
            <li @click="cur=1">
              <p :class="{ active: cur == 1 }">企业经营数字化平台</p>
              <p :class="{ underline: cur == 1 }"></p>
            </li>
          </ul>
        </div>
        <div class="case1" v-show="cur==0">
          <div class="case2">
            <div class="mengceng">
              <div class="mengceng1">
                <div class="system">
                  <div class="system1">
                    <span class="name1">供应商管理系统</span>
                    <span
                        class="introduc1">以采购管控为核心,协同管理为辅助,数据分析为支撑,搭建企业标准采购数字化平台,实现供应链上下游的数据互联互通,加强成本控制,提高采购管理效率.</span>
                    <div class="gogo" @click="gogo1()">
                      <span class="lian">查看详情</span>
                      <img src="../assets/img11.1.png">
                    </div>
                  </div>
                  <img class="peitu" src="../assets/img12.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case1" v-show="cur==1">
          <div class="case2">
            <div class="mengceng">
              <div class="mengceng1">
                <div class="system">
                  <div class="system1">
                    <span class="name1">企业经营数字化平台</span>
                    <span
                        class="introduc1">通过全新架构打造的以Oracle为核心的成本分析中心，助力企业高效完成海量成本数据的计算分析，快速展示企业盈利能力，详细呈现业务决策收益</span>
                    <div class="gogo" @click="gogo2()">
                      <span class="lian">查看详情</span>
                      <img src="../assets/img11.1.png">
                    </div>
                  </div>
                  <img class="peitu" src="../assets/img137.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    四层-->
    <div class="four-storey">
      <div class="zss">
        <div class="btt">
          <div class="bts">
            <img src="../assets/img192.png">
            <span class="zbt">企业服务诚信品牌</span>
            <img src="../assets/img191.png">
          </div>
          <span class="fbt">我们始终秉承着 客户至上、人才为本、艰苦奋斗、团队合作、自我批判 的核心价值观，用创新的商业技术服务与客户一起改变世界！</span>
        </div>
        <div class="rz">
          <div class="rz1">
            <div class="rzt">
              <div class="rzs">
                <span>30</span>
                <span>+</span>
              </div>
              <span class="rzn">权威认证</span>
            </div>
            <div class="rzz" v-for="(item, index ) in rzData" :key="index">
              <div class="rzz1">
                <span>{{item.num1}}</span>
                <span>+</span>
              </div>
              <div class="rzz2">{{item.na}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    五层-->
    <div class="five-storey">
      <div class="zss">
        <div class="spreadhead">
          <div class="subhead">
            <div class="moit">
              <img class="img3" src="../assets/img3.png">
              <span>服务范围</span>
              <img class="img4" src="../assets/img4.png">
            </div>
          </div>
          <span class="details">提供数字运营服务、软件开发、云计算、软件外包等各类信息化产品和服务。</span>
        </div>
        <div class="box3">
          <div class="box4">
            <div class="outer" v-for="(item , index) in loopData" :key="index">
              <div class="group3">
                <div class="box5">
                  <img class="group4" :src="item.smg">
                  <span class="info">{{ item.yizhi }}</span>
                </div>
                <span class="info2">{{ item.erzhi }}</span>
                <div class="box6" @click="din(item)"><span class="word15">查看详情 <span class="r-jt"></span></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    六层-->
    <div class="six-storey">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>合作伙伴</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
        <span class="details">华钛优秀合作伙伴</span>
      </div>
      <div class="merchant">
        <img src="../assets/img14.png">
      </div>
    </div>
    <!--    底层-->
    <div class="ground-floor">
      <div class="fbox">
        <div class="fbox1">
          <div class="fbox2">
            <div class="lianxi">
              <div class="fico">
                <img class="ficon" src="../assets/img2.png">
                <p class="fdesignation">华钛信息</p>
              </div>
              <div class="contway">
                <div class="special">
                  <span>联系电话：</span>
                  <span>021-55962658</span>
                </div>
                <div class="special">
                  <span>商务合作：</span>
                  <span>business@huataish.com</span>
                </div>
              </div>
            </div>
            <div class="cut-line"></div>
            <div class="explain">
              <div class="explain1">
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">行业方案</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                        <span @click="din(item)">{{ item.lei }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">自主产品</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                        <span @click="din(item)">{{ item.lei1 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename">服务范围</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                        <span @click="din(item)">{{ item.lei2 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="cl()">客户案例</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="hz()">合作伙伴</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                        <span>{{ item.lei3 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rice">
                  <div class="rice1">
                    <span class="ricename" @click="xx()">华钛信息</span>
                    <ul>
                      <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                        <span @click="din(item)">{{ item.lei4 }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cut-line1"></div>
          <div class="safety">
            <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Home.vue",
  data() {
    return {
      curss:0,
      cur: 0,
      baData:[
        {
          ban:require('../assets/img1.png'),
          bnr:"“知识+科技”",
          bbt:"助力行业生态体系健康有序发展",
          bft:"始终坚持以开放的姿态与客户、合作伙伴及友商合作共赢，扩大产业价值来助力行业有序发展"
        },
        {
          ban:require('../assets/img203.png'),
          bnr:"华钛信息",
          bbt:"成就美好数字化世界",
          bft:"信息引领发展，科技创造未来，华钛信息为行业数字化注入新价值"
        },
      ],
      hzData:[
        {
        hzz:"金融行业",
        url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      rzData:[
          {
            num1:120,
            na:"累积项目"
          },
        {
          num1:100,
          na:"企业人数"
        },
        {
          num1:200,
          na:"累积客户"
        },
      ],
      Dataes: [
        {
          icon: require("../assets/img7.png")
        },
        {
          icon: require("../assets/img199.png")
        },
      ],
      Dataes1:[
        {
          icon: require("../assets/img175.png")
        },
        {
          icon: require("../assets/img202.png")
        },
      ],
      Dataes2:[
        {
          icon: require("../assets/img174.png")
        },
        {
          icon: require("../assets/img200.png")
        },
      ],
      Dataes3:[
        {
          icon: require("../assets/img173.png")
        },
        {
          icon: require("../assets/img201.png")
        },
      ],
      loopData: [
        {
          smg:require("../assets/img129.png"),
          yizhi: "定制化开发服务",
          erzhi: "为企业量身定制专属企业开发，个性化配置满足企业需求，功能强大支持多场景联动。",
          url:'/CustoMization'
        },
        {
          smg:require("../assets/img134.png"),
          yizhi: "产品解决方案服务",
          erzhi: "挖掘业务痛点，剖析行业趋势，致力于打造符合业务需求的一体化产品解决方案。",
          url:'/Product'
        },
        {
          smg:require("../assets/img131.png"),
          yizhi: "平台研发服务",
          erzhi: "以大数据、云计算技术，提供专业的数字化服务，驱动商业模式创新，加速数字化转型",
          url:'/PlatForm'
        },
        {
          smg:require("../assets/img133.png"),
          yizhi: "咨询服务",
          erzhi: "华钛信息咨询服务包括数字化转型及交互设计，为企业提供专业化的咨询服务。",
          url:'/DigiTization'
        },
        {
          smg:require("../assets/img130.png"),
          yizhi: "运营服务",
          erzhi: "为企业业务运营难点提供专业化的解决方案，助力企业提升运营效能",
          url:'/Epiboly'

        },
        {
          smg:require("../assets/img132.png"),
          yizhi: "运维服务",
          erzhi: "华钛信息为企业提供基础的IT运维服务支持，让企业能够专注于其核心业务板块。",

          url:'/OperAtion'
        }
      ],
      foolDatas: [
        {
        lei: "金融行业",
        url: '/FinAnce'
      },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [
      ],
      foolDataess: [
      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    gogo1(){
      window.open('/IndepenDent','_blank')
    },
    gogo2(){
      window.open('/IndepenDent','_blank')

    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>


<style scoped>
img {
  width: 100%;
}
.overstory {
  width: 100%;
  height: 640px;
  /*background-image: url("../assets/img1.png");*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*background-position: center;*/
}
.overstory .banner{
  width: 100%;
  height: 640px;
}
.bat{
  width: 100%;
  height: 640px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.overstory .nong{
  display: flex;
  flex-direction: column;
  width: 462px;
  height: 165px;
  margin: 210px 0px 258px 0px;
  position: absolute;
}
.overstory .nong span:first-child{
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  margin-bottom: 2px;
}
.overstory .nong span:nth-child(2){
  width: 420px;
  height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 42px;
  margin-bottom: 36px;
}
.overstory .nong span:last-child{
  width: 462px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.bilaminar {
  width: 100%;
  height: 642px;
}
.zsd{
  width: 1180px;
  height: 100%;
  margin: 0 auto;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  margin-bottom: 48px;
  /*background: red;*/
}
.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}
.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}
.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}
.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
  font-weight: 510;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #202B4E;
  margin: auto;
}
/*Tab选项*/
.options {
  width: 1080px;
  height: 520px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.options .select {
  display: flex;
  width: 379px;
  flex-direction: column;
  /*background: #2c3e50;*/
}
.options .select li {
  height: 108px;
  border-radius: 4px;
  /*box-shadow: 0px 2px 30px 0px rgb(255 175 69 / 50%);*/
  /*background: linear-gradient(135deg, #FFC170 0%, #FFA836 100%);*/
  width: 379px;
}
.options .select li p:hover .numbers{
  font-size: 48px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  color: black;
  line-height: 40px;
}
.options .select li p:hover .sponsor span:first-child{
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #202B4E;
  line-height: 20px;
}
.options .select li p:hover .sponsor span:last-child{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 22px;
}
.options .select li p{
  display: flex;
  height: 108px;
  width: 379px;
}
.options .select li p.active{
  width: 379px;
  height: 108px;
  background: linear-gradient(135deg, #FFC170 0%, #FFA836 100%);
  box-shadow: 0px 2px 30px 0px rgba(255, 175, 69, 0.5);
  border-radius: 4px;
  display: flex;
}
.options .select li .message {
  display: flex;
  width: 315px;
  height: 51px;
  margin-top: 37px;
  margin-left: 34px;
}
.options .select li .message .jt{
  width: 46px;
  height: 46px;
  /* margin-top: 13px; */
  /* margin-left: 13px; */
  background-color: #FFFFFF;
  background-image: url("../assets/img5.1.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  margin-left: 25px;
}
.options .select li p.active .message .jt{
  background-image: url("../assets/img5.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.options .select li:hover .message .jt{
  background-image: url("../assets/img5.2.png");
  background-repeat: no-repeat;
  background-position: center;
}
.options .select li .message .numbers {
  display: block;
  width: 63px;
  height: 48px;
  color: rgba(156, 159, 178, 1);
  font-size: 48px;
  font-family: DINCondensed-Bold;
  white-space: nowrap;
  line-height: 48px;
  text-align: left;
  margin-top: 3px;
}
.options .select li .message .numbers.active{
  display: block;
  width: 63px;
  height: 48px;
  font-size: 48px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  color: #202B4E;
  line-height: 37px;
}
.options .select li .message .sponsor {
  display: flex;
  flex-direction: column;
  width: 144px;
  height: 50px;
}
.options .select li .message .sponsor span:first-child {
  width: 80px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(156, 159, 178, 1);
  font-size: 20px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}
.options .select li .message .sponsor span:first-child.active{
  width: 80px;
  height: 20px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #202B4E;
  line-height: 20px;
}
.options .select li .message .sponsor span:last-child {
  width: 144px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(156, 159, 178, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
  margin-top: 8px;
}
.options .select li .message .sponsor span:last-child.active{
  width: 112px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 22px;
}
.options .select li .message .arrows {
  width: 16px;
  height: 17px;
  background: url(../assets/img5.png) 100% no-repeat;
  margin: 17px 0 0 80px;
}
/*对应详细介绍*/
.options .cabient {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 560px;
  margin-top: 12px;
}

.options .cabient .explain {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 142px;
}

.options .cabient .explain .duiying {
  width: 96px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 24px;
  text-align: center;
}

.options .cabient .explain img {
  width: 26px;
  height: 6px;
  margin-top: 12px;
}

.options .cabient .explain .shuoming {
  width: 480px;
  height: 66px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(156, 159, 178, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}


/*三层*/
.trilaminar {
  width: 100%;
  height: 515px;
  background-color: rgba(249, 250, 255, 1);
  margin-top: 48px;
}

.trilaminar .group1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 53;
  height: 296px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  width: 1179px;
  left: 50%;
  transform: translateX(-50%);
}

.trilaminar .group2 {
  display: flex;
  flex-direction: column;
  width: 1179px;
  height: 60px;
  line-height: 55px;
}

.trilaminar .group2 ul {
  width: 384px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.trilaminar .group2 ul li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.trilaminar .group2 ul li p:first-child {
  font-size: 18px;
  font-weight: 400;
}

.trilaminar .group2 ul li p.active {
  color: #124BFF;
  font-weight: 510;
}

.trilaminar .group2 ul li .underline {
  width: 36px;
  height: 3px;
  margin: 0 auto;
  background: #124BFF;
}

.trilaminar .group1 .case1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 469px;
  border-top: 1px solid #EEEEEE;
}

.trilaminar .group1 .case1 .case2 {
  width: 100%;
  height: 239px;
  border-bottom: 1px solid #EEEEEE;
  background-image: url("../assets/img9.png");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
}

.trilaminar .group1 .case1 .case2 .mengceng {
  width: 100%;
  height: 239px;
  background-image: url("../assets/img8.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.trilaminar .group1 .case1 .case2 .mengceng1 {
  width: 100%;
  height: 239px;
  background-image: url("../assets/img10.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.trilaminar .group1 .case1 .case2 .system {
  position: relative;
  display: flex;
  width: 1101px;
  height: 191px;
  top: 28px;
  justify-content: space-between;
}

.trilaminar .group1 .case1 .case2 .system .peitu {
  width: 300px;
  height: 184px;
}

.trilaminar .group1 .case1 .case2 .system1 {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 30px;
  width: 645px;
}

.trilaminar .group1 .case1 .case2 .system1 .name1 {
  font-size: 24px;
}

.trilaminar .group1 .case1 .case2 .system1 .introduc1 {
  width: 645px;
  height: 84px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  line-height: 28px;
  text-align: left;
  margin-top: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trilaminar .group1 .case1 .case2 .system1 .gogo {
  display: flex;
  height: 20px;
  margin: 11px 0 0 1px;
}

.trilaminar .group1 .case1 .case2 .system1 .lian {
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(18, 75, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}

.trilaminar .group1 .case1 .case2 .system1 img {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-left: 4px;
  transition: all .5s;
}
.trilaminar .group1 .case1 .case2 .system1  .gogo:hover img{
  margin-left: 12px;
}
.trilaminar .group1 .case1 .case3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.trilaminar .group1 .case1 .case3 .case4 {
  height: 22px;
  border-radius: 11px;
  background-color: rgba(239, 240, 242, 1);
  width: 66px;
  margin: 24px 0 0 30px;
}

.trilaminar .group1 .case1 .case3 .case4 span {
  width: 48px;
  height: 17px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(128, 140, 159, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
  margin: 3px 0 0 9px;
}

.trilaminar .group1 .case1 .case3 .main {
  display: flex;
  justify-content: space-between;
  width: 1036px;
  height: 130px;
  margin: 16px 0 0 30px;
}

.trilaminar .group1 .case1 .case3 .main .line1 {
  width: 2px;
  height: 130px;
  background: #EEEEEE;
}

.trilaminar .group1 .case1 .case3 .main .product1 {
  display: flex;
  flex-direction: column;
  width: 446px;
  height: 107px;
  margin-top: 10px;
}

.trilaminar .group1 .case1 .case3 .main .product1 .headline {
  width: 256px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(41, 44, 62, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
}

.trilaminar .group1 .case1 .case3 .main .product1 .netter {
  width: 446px;
  height: 66px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(156, 159, 178, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*四层*/
.four-storey {
  display: flex;
  width: 100%;
  height: 377px;
  background-image: url("../assets/img13.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.four-storey .btt{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  position: relative;
  top: 64px;
  justify-content: space-between;
}
.four-storey .btt .bts{
  display: flex;
  margin: 0 auto;
}
.four-storey .btt .bts img{
  display: block;
  width: 64px;
  height: 18px;
  margin-top: 3px;
}
.four-storey .btt .bts .zbt{
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  margin: 0px 24px;
}
.four-storey .btt .fbt{
  width: 542px;
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin:0 auto;
}
.four-storey .rz{
  width: 100%;
  height: 122px;
  position: relative;
  top: 136px;
}
.four-storey .rz .rz1{
  display: flex;
  justify-content: space-between;
  width: 1074px;
  height: 100%;
  margin: 0 auto;
}
.four-storey .rz .rz1 .rzt{
  display: flex;
  flex-direction: column;
}
.four-storey .rz .rz1 .rzt .rzn{
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}
.four-storey .rz .rz1 .rzt .rzs span:first-child{
  display: inline-block;
  width: 79px;
  height: 70px;
  font-size: 70px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 84px;
  transform: scaleX(0.8);
}
.four-storey .rz .rz1 .rzt .rzs span:last-child{
  width: 25px;
  height: 56px;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  position: relative;
  top: -34px;
  left: 10px;
}
.four-storey .rz .rz1 .rzz{
  display: flex;
  flex-direction: column;
}
.four-storey .rz .rz1 .rzz span:first-child{
  display: inline-block;
  width: 79px;
  height: 70px;
  font-size: 70px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 84px;
  transform: scaleX(0.6);
}
.four-storey .rz .rz1 .rzz span:last-child{
  width: 25px;
  height: 56px;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 56px;
  position: relative;
  top: -34px;
  left: 10px;
}
.four-storey .rz .rz1 .rzz .rzz2{
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}
/*五层*/
.five-storey {
  width: 100%;
  height: 780px;
  background: #F9FAFF;
}

.box3 {
  width: 100%;
  height: 562px;
  margin-top: 48px;
}

.box4 {
  display: flex;
  flex-direction: row;
  width: 1186px;
  height: 562px;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 1186px;
  min-width: 1186px;
}

.box4 .outer {
  display: flex;
  flex-direction: column;
  height: 220px;
  border-radius: 4px;
  border: 1px solid rgba(229, 231, 239, 1);
  background-image: url("../assets/img193.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 20px 20px 0;
  box-shadow: 3px 3px 16px 0px #DEE3ED;
}

.box4 .outer .group3 {
  height: 100%;
  margin: 35px 50px 0 32px;
}

.box4 .outer .group3 .box5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 196px;
  height: 29px;
}

.outer:nth-child(3n) {
  margin-right: 0;
}

.outer:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
.outer:hover{
  box-shadow: 0px 2px 30px 0px #d2d3d9;
}

.box4 .outer .group3 .box5 .group4 {
  width: 29px;
  height: 29px;
}

.box4 .outer .group3 .box5 .info {
  width: 144px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(41, 44, 62, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  margin-top: 2px;
}

.box4 .outer .group3 .info2 {
  width: 294px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(128, 140, 159, 1);
  font-size: 14px;
  letter-spacing: 0.875px;
  line-height: 22px;
  text-align: left;
  margin-top: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box4 .outer .group3 .box6 {
  height: 36px;
  border-radius: 18px;
  width: 106px;
  margin: 46px 0 0 1px;
}

.box4 .outer .group3 .box6 .word15 {
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(13, 80, 225, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}
.box4 .outer .group3 .box6 .word15 .r-jt{
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 1px solid #124BFF;
  border-left-width: 0;
  border-bottom-width: 0;
  transition: all .5s;
  transform: matrix(0.71,0.71,-0.71,0.71,0,0);
}
.outer:hover .word15 .r-jt{
  margin-left: 20px;
}
/*六层*/
.six-storey {
  width: 100%;
  height: 319px;
  background: #FFFFFF;
}

.six-storey .merchant {
  width: 100%;
  margin-top: 48px;
}

.six-storey .merchant img {
  display: block;
  width: 1179px;
  height: 101px;
  margin: 0 auto;
}


</style>
<!--element样式-->
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}
.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}

.carousel .el-carousel__indicator.is-active button {
  width: 6px;
  height: 6px;
  background-color: blue;
  border-radius: 50%;
}

.carousel .el-carousel__button {
  width: 6px;
  height: 6px;
  background-color: #D8D8D8;
  border-radius: 50%;
}

.carousel .el-carousel__indicators {
  display: inline-block;
  position: relative;
  padding: 0px 44%;
}
.bilaminar .el-carousel--horizontal{
  width: 418px;
  margin: 0 auto;
}
</style>